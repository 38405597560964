*{
	max-width: 100vw;
	overflow-x: none;
}

a {
	color: black;
	text-decoration: none;
}

a:hover{
	text-decoration: underline;
}

ul{
	list-style: none;
	border: none;
}

.fakeBody{
	max-height: 100vh;
}

.nav-blank-adjust{
	height: 50px;
}

.section-contact{
	min-height: 91.8vh;
}

.section-row-R:first-of-type{
	border-bottom: 1px solid #FEEC35;
}


iframe{
	width: 100%;
	height: 100%;
}


.section-contact{
	width: 100vw;
	border-top: 1px solid #FEEC35;
	display: inline-flex;
	flex-wrap: wrap;
	margin-left: 10%;
}

.section-column{
	width: 50%;
}

.iframe-handle{
	width: 25vw;
	height: 25vw;
	border: none;
	padding-top: 5vh;
	padding-left: 5vh;
	padding-right: 5vh;
}



@media only screen and (max-width: 600px) {
	.section-contact{
		margin: 0;
	}

	.iframe-handle{
	width: 25vw;
	height: 25vw;
	border: none;
	padding-top: 4vh;
	padding-left: 5vh;
	padding-right: 5vh;
}

	.section-row-R{
		margin-left: 30%;
		margin-top: 0;
	}

	.section-row-R ul{
		padding-left: 0;
		margin-left: 0;
	}
}

@media only screen and (min-width: 600px) and (max-width: 996px) {
	.section-contact{
		margin: 0;
	}

	.section-row-R{
		margin-left: 30%;
		margin-top: 0;
	}

	.section-row-R ul{
		padding-left: 0;
		margin-left: 0;
	}

	.iframe-handle{
	width: 25vw;
	height: 25vw;
	border: none;
	padding-top: 5vh;
	padding-left: 5vh;
	padding-right: 5vh;
}

.section-row-R ul{
		padding-left: 0;
		margin-left: 0;
	}

}


@media only screen and (min-width: 996px) {
	.section-contact{
		margin-left: 20%;
	}

	.section-row-R{
	margin-left: 10%;
}

.section-row-R ul{
		padding-left: 0;
		margin-left: 0;
	}

}
*{
	max-width: 100vw;
	overflow-x: none;
}

.nav-blank-adjust{
	height: 50px;
}

.fakeBody{
	max-height: 100vh;
}

.staff{
	line-height: 3;
}

.section-row-R:first-of-type{
	border-bottom: 1px solid #FEEC35;
}

.section-about{
	margin-bottom: .15vh;
}


@media only screen and (max-width: 600px) {
	.section-column:first-of-type{
		height: 30vh;
		width: 100vw;
	}

	.section-column img{
		width: 20%;
		min-width: 300px;
		position: absolute;
		top: 10%;
		left: 50%;
		transform: translate(-50%, -10%);
		z-index: -1;
	}
	

	.about-section-right{
		padding: 0%;
		min-height: 59vh;
		width: 90%;
		padding-left: 0;
		margin-left: 0%;
	}

	.section-about{
		margin-left: 0;
	}

	.section-row-R{
		margin-left: 12%;
		padding-right: 0%;
	}
}

@media only screen and (min-width: 600px) and (max-width: 996px) {
	.section-column:first-of-type{
		height: 30vh;
		width: 100vw;
	}

	.section-column img{
		width: 20%;
		min-width: 300px;
		position: absolute;
		top: 10%;
		left: 50%;
		transform: translate(-50%, -10%);
		z-index: -1;
	}
	

	.about-section-right{
		padding: 0%;
		min-height: 59vh;
		width: 90%;
		padding-left: 0;
		margin-left: 0%;
	}

	.section-about{
		margin-left: 0;
	}

	.section-row-R{
		margin-left: 12%;
		padding-right: 0%;
	}
}


@media only screen and (min-width: 996px) {
	.section-row-R{
		padding-right: 30%;
	}
	.section-column img{
		width: 40vw;
		min-width: 400px;
		padding-left: 20%;
	}
	.section-about{
		display: flex;
		width: 100%;
		border-top: 1px solid #FEEC35;
		min-height: 91.6vh;
	}

}
*{
	max-width: 100vw;
	overflow-y: none;
}

/* SECTIONS */
.section:nth-of-type(2n){
	background-color: #FEEC35;
}


/* LOGO */

#logo{
	height: 100vh;
	max-width: 100vw;
}

#logo img{
	width: 30%;
	min-width: 300px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: -1;
	
}


/* ABOUT / MEDIA */
#media_planning h2{
	margin: 0;
}

#media_planning, #about{
	padding: 5% 20% 5% 20%;
}

#media_planning ul{
	list-style-type: none;
	padding-left: 0;
	line-height: 6vh;
}

#media_planning ul li{
	position: relative;
	cursor: pointer;
}

#media_planning ul li:hover .checkmark{
	visibility: visible;
}

.checkmark{
	position: absolute;
	width: 3%;
	min-width: 30px;
	visibility: hidden;
}


#media_planning{
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat, no-repeat;
}

@media only screen and (max-width: 600px) {

	#media_planning, #about {
	padding: 5% 5% 5% 5%;
	font-size: 16px;
}

}


/* WORK */
#past_work{
	background-color: black;
	max-width: 100vw;
	padding: 0 0%;
	}

#past_work_header h2, #past_work_header h3{
	color: white;
	padding-left: 5%;
	margin: 0;
	padding-bottom: 0;
}

#past_work_header h2{
	padding-top: 2%;
}

.image-row {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  padding: 4vh 4vw;
}

/* Create four equal columns that sits next to each other */
.image-column {
  -ms-flex: 15%; /* IE10 */
  flex: 15%;
  max-width: 15%;
  padding: 2%;
}

.corner-icon{
	position: absolute;
	top: 0;
	right: 0;
	height: 4%;
	width: 5%;
	z-index: 40;
}

.corner-icon img{
	position: absolute;
	top: 0;
	right: 0;
	z-index: 0;
}

.image-icon-div{
	cursor: pointer;
}

.popUpHolder{
	position: relative;
}

.image-column img {
  margin: 10% 0;
  vertical-align: middle;
  width: 100%;
  max-width: 300px;
  z-index: 3;
}

@media screen and (max-width: 900px) {
  .image-column {
    -ms-flex: 20%;
    flex: 20%;
    max-width: 15%;
  }

  #past_work{
  	padding: 0 0%;
  }
}


@media screen and (max-width: 700px) {
  .image-column {
    -ms-flex: 20%;
    flex: 20%;
    max-width: 20%;
  }

  #past_work{
  	padding: 0 0%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .image-column {
    -ms-flex: 28%;
    flex: 28%;
    max-width: 28%;
  }
  #past_work{
  	padding: 0 0%;
  	max-width: 100vw;
  }
}

/* CONTACT */
#contact{
	width: 95%;
	margin-left: 2%;
	
}


@media only screen and (max-width: 600px) {
	#contact{
		width: 90%;
		margin-left: 10%;
		min-width: 0px;
	}
}




#rightContact{
	text-align: right;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url('./../assets/zena-icons-animation-contact-02.gif');
}

#rightContact a{
	text-decoration: none;
	color: black;
}

#rightContact a:hover{
	text-decoration: underline;
}

#leftContact{
	text-align: left;
	z-index: 1;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url('./../assets/zena-icons-animation-contact.gif');
}

.contactSection{
	display: inline-block;	
	width: 50%;
	min-width: 250px;
}

.contactSection ul{
	list-style-type: none;
	padding: 0;
}

/* POPUP */

.image-button{
	cursor: pointer;
}

.popUp{
	max-height: 100vh;
	max-width: 100vw;
	border: 1px solid black;
	background-color: white;
	overflow-x: none;
	position: fixed;
	top: 0;
	left:0;
	right:0;
	bottom: 0;
	z-index: 99;
	overflow-y: scroll;
}
.popUp-overlay{
	height:100vh;
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
	background-color: black;
	opacity: .5;
	z-index: 98;
}

.popUp-section{
	margin: 0 auto;
	padding: 10% 10%;
	position: relative;
	max-width: 100%;
	white-space: pre-wrap;

}

.popUp-section:nth-of-type(2n) {
	background-color: #FEEC35;
}

.popUp-section img {
	width: auto;
	max-height: 300px;
	max-width: 300px;
	position: relative;
	display: block;
	left: 47%;
	transform: translate(-50%, 0%);
}

.popUp-close {
	position: fixed;
	cursor: pointer;
	color: #FEEC35;
	font-size: 45px;
	-webkit-text-stroke-width: 1px;
   -webkit-text-stroke-color: black;
	top: 0;
	left: 85%;
	z-index: 100;
}

.popUp-header{
	background-color: black;
	min-height: 60vh;
	color: white;
}

.popUp h2{
	font-family: "Lato", sans-serif;
	font-weight: 300;
}

.popUp-header p{
	margin-top: 5%;
	position: relative;
	max-width: 100%;
	white-space: pre-wrap;
	font-family: "Lato", sans-serif;
	font-weight: 800;
	font-style: italic;
}

.popUp-image-background{
	background-color: black;
	background-size: cover;
	position:absolute;
	z-index: 98;
	width: 100%;
	height: 100%;
	opacity: 0.3;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.popUp-back{
	cursor: pointer;
	bottom: 0;
	margin-top: 5%;
}

/* POPUP STATISTIC TABLE */

.statsTable{
	margin: 0 auto;
	width: 100%;
	min-width: 250px;
	font-size: 3vh;
	border-collapse: collapse;
	min-height: 25vh;
}

.statsTable tr:first-of-type{
	background-color: #FEEC35;
}

.statsTable tr, th{
	border: none;
}

.statsTable td{
	border-top: 1px solid black;
	border-right: 1px solid black;
	padding-left: 1%;
	padding-right: 1%;
}

.statsTable td:nth-of-type(2n){
	text-align: right;
}
@font-face{
	font-family: "SpaceMono-Regular";
	src:url('https://firebasestorage.googleapis.com/v0/b/zdg-site.appspot.com/o/fonts%2FSpaceMono-Regular.ttf?alt=media&token=7e52e216-fca6-4b35-9e2e-0c0cf5ac015b');
}

@font-face{
	font-family: "Avenir Condensed";
	font-weight: normal;
	font-style: normal;
	src: url('https://firebasestorage.googleapis.com/v0/b/zdg-site.appspot.com/o/fonts%2FAvenirNext%2FAvenirNextCondensed-Regular-08.ttf?alt=media&token=89baf7d6-81a3-47b7-a145-2257d2ac3d85');
}

*{
	max-width: 100vw;
	overflow-x: none;
}

body{
	margin: 0;
	padding: 0;
	overflow-x: hidden;
	font-family: "SpaceMono-Regular";
	font-size: 18px;
}

h2{
	font-family:"Avenir Condensed";
	font-weight: normal;
	font-size: 30px;
}

h3{
	font-family: "Lato", sans-serif;
	font-size: 20px;
	font-weight: 500;
	margin: 0;
	padding: 0;
}

h4{
	font-size: 20px;
	font-family: "Lato", sans-serif;
	font-weight: 300;
}


.no-scroll{
	overflow-y: hidden;
}
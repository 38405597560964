*{
	max-width: 100vw;
	overflow-x: none;
}

footer{
	background-color:black;
	max-width: 100vw;
	text-align: right;
	color: white;
	font-size: 2vh;
	padding-right: 2vw;
}

nav{
	position:fixed;
	-webkit-overflow-scrolling: touch;
	top: 0;
	left: 0;
    right: 0;
    width: 100vw;
	z-index: 20;
	overflow: hidden;
	touch-action: none;
}

.whiteColor a{
	color: white;
}

.whiteColor li{
	color: white;
}

.blackColor a{
	color: black;
}

.blackColor li{
	color: white;
}

nav .nav_container{
	display: inline-block;
	width: 45vw;
	margin-left: 2.0vw;

}

nav .nav_container ul{
	text-align: right;
}

nav .nav_container a{
	text-decoration: none;
	font-size: 80%;
}

nav .nav_container a:visited{
	color: black;
}

nav .nav_container a:hover{
	color: #FEEC35;
}

nav .nav_container a:active{
	color: black;
}

nav  #right li{
	margin-left: 1vw;
}

nav .nav_container ul a:active{
	text-decoration: underline;
}

.nav_container{
	text-align: left;
}

.nav_container ul li{
	list-style-type: none;
	white-space: nowrap;
	display: inline;
}

#nav_blank_adjust{
	height:50px;
}